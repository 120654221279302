import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	a: {
		color: theme.palette.common.white,
		fontSize: theme.typography.smallText.fontSize,
		margin: theme.spacing(0, 2, 1, 0),
		"&:hover": {
			textDecoration: "underline"
		}
	}
}));

const Metamenu = ({ data }) => {
	const css = useStyles();

	return (
		<div className={css.root}>
			{data?.items?.map(({ id, link, target, title }, i) => {
				return (
					<a className={css.a} href={link} target={target} key={id}>
						{title}
					</a>
				);
			})}
		</div>
	);
};

Metamenu.propTypes = {
	data: PropTypes.object.isRequired
};

export default Metamenu;
