import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles((theme) => ({
	root: {
		background: theme.palette.grey[50],
		display: "flex",
		position: "fixed",
		top: 64,
		width: "100%",
		zIndex: theme.zIndex.appBar - 1,
		[theme.breakpoints.up("lg")]: {
			top: 96
		}
	},
	container: {
		display: "flex",
		overflow: "hidden",
		minHeight: 64,
		padding: theme.spacing(0, 1),
		WebkitOverflowScrolling: "touch"
	},
	scroller: {
		display: "inline-block",
		flex: "1 1 auto",
		marginBottom: 0,
		overflowX: "scroll",
		position: "relative",
		scrollbarWidth: "none",
		whiteSpace: "nowrap",
		"&::-webkit-scrollbar": {
			display: "none"
		}
	},
	wrapper: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		gap: theme.spacing(4),
		[theme.breakpoints.up("lg")]: {
			justifyContent: "center"
		}
	},
	item: {
		alignItems: "center",
		color: theme.palette.text.primary,
		cursor: "pointer",
		display: "flex",
		fontFamily: "Rum-Serif",
		fontSize: 16,
		fontWeight: 600,
		minHeight: 48,
		opacity: 1,
		padding: theme.spacing(1, 1.5),
		position: "relative",
		transition: theme.transitions.create(["opacity"], {
			duration: theme.transitions.duration.short
		}),
		"&.active": {
			opacity: 0.7
		},
		"&:hover": {
			opacity: 0.7
		},
		"&:after": {
			background: theme.palette.divider,
			content: "''",
			height: 16,
			marginTop: -8,
			position: "absolute",
			right: -16,
			top: "50%",
			width: 1
		},
		"&:last-child:after": {
			display: "none"
		}
	}
}));

export default styles;
