import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import ReactAnimation from "@micado-digital/react-animation/ReactAnimation";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import Header from "../../components/header";
import Footer from "../../components/footer";
import AnchorMenu from "../../components/anchorMenu";
import PageContext from "@PageContext";
import WebProfileContext from "@WebProfileContext";
import ContentElements from "./contentElements";
import AnimationTeaser from "./animationTeaser";
import useConsent from "../../../hooks/useConsent";

// calculate vh (viewport height) for mobile devices

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		"& .mco-animation-01__texts p": {
			color: "#FFFFFF !important",
			filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.24))"
		}
	},
	animationWrapperIntro: {
		height: "max(350px, calc(var(--vh, 1vh) * 100))"
	},
	animationWrapper: {
		height: "max(350px, calc(var(--vh, 1vh) * 75))",
		paddingTop: 64,
		[theme.breakpoints.up("lg")]: {
			paddingTop: 96
		},
		"& img": {
			objectPosition: "top"
		}
	}
}));

const LayoutStandard = ({ variant }) => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const [profileData] = useContext(WebProfileContext);
	const { elements } = pageData;
	const css = useStyles();
	const teaserURL = REACT_APP_PATH + "/teaser.json.api?groupID=18&format=v2";
	const isIntro = variant === "intro";
	const consent = useConsent();

	const footerAddresses = [
		{
			id: 1,
			title: profileData?.name,
			street: profileData?.street,
			city: profileData?.city,
			zip: profileData?.zip,
			phone: profileData?.phone,
			mail01: profileData?.mail
		}
	];

	useEffect(() => {
		if (variant) {
			document.body.classList.add(variant);
		}
	}, [variant]);

	let mediaFormats = null;

	if (pageData?.animationID && isIntro) {
		mediaFormats = { xs: "animation-mobile", sm: "animation" };
	} else {
		mediaFormats = { xs: "animation-small-mobile", sm: "animation-small" };
	}

	const anchorItems =
		elements?.filter(({ anchor }) => anchor?.visible)?.map((item) => item?.anchor) ?? [];

	return (
		<>
			<Header hasAnchorMenu={anchorItems && anchorItems?.length > 0} />
			{anchorItems && anchorItems?.length > 0 && <AnchorMenu items={anchorItems} />}
			{pageData?.animationID && variant !== "noanimation" && (
				<div
					className={clsx(
						"container-animation",
						css.root,
						pageData?.animationID && isIntro ? css.animationWrapperIntro : css.animationWrapper
					)}
				>
					<ReactAnimation
						api={`${REACT_APP_PATH}/animationV3.json.api`}
						id={pageData?.animationID}
						mediaFormats={mediaFormats}
						prevIcon={<ReactSVGIcon src="/img/icons/chevron-left.svg" size={24} />}
						nextIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={24} />}
						showArrows
						showBullets
						webpFallback
					/>
					{pageData?.animationID && variant !== "noanimation" ? (
						<AnimationTeaser teaserURL={teaserURL}></AnimationTeaser>
					) : (
						<></>
					)}
				</div>
			)}
			<main>
				<ContentElements />
			</main>
			<Footer addresses={footerAddresses} />
			<micado-ial
				key={consent}
				language={pageData?.lang}
				path={REACT_APP_PATH}
				page-id={pageData?.id}
			></micado-ial>
		</>
	);
};

export default LayoutStandard;
