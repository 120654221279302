import React, { useEffect, useContext } from "react";
import loadable from "@loadable/component";
import isEmpty from "lodash/isEmpty";
import PageContext from "@PageContext";
import WebProfileContext from "@WebProfileContext";
import ReactMetaData01 from "@micado-digital/react-metadata/ReactMetadata01";
import DetectLayout from "./detectLayout";

const ReactTracking = loadable(() => import("@micado-digital/react-tracking/ReactTracking"));

const DetectPage = () => {
	const [pageData] = useContext(PageContext);
	const { lang, meta = {} } = pageData;
	const [profileData] = useContext(WebProfileContext);
	const { trackingCodes = [] } = profileData;

	useEffect(() => {
		document.body.addEventListener("mw-consent-accepted", (event) => {
			if (window.dataLayer)
				window.dataLayer.push({ event: "consent-accepted", data: event.detail });
		});

		document.body.addEventListener("mw-consent-customized", (event) => {
			if (window.dataLayer)
				window.dataLayer.push({ event: "consent-customized", data: event.detail });
		});

		document.body.addEventListener("mw-consent-ignored", (event) => {
			if (window.dataLayer)
				window.dataLayer.push({ event: "consent-ignored", data: event.detail });
		});
	}, []);

	if (isEmpty(pageData)) return <></>;

	const {
		canonical: metaCanonical,
		defaultLang: metaDefaultLang,
		description: metaDescription,
		indexable: metaIndexable,
		image: metaImage,
		keywords: metaKeywords,
		lat: metaLat,
		links: metaLinks,
		lng: metaLng,
		title: metaTitle
	} = meta;

	return (
		<>
			<ReactMetaData01
				canonical={metaCanonical}
				defaultLang={metaDefaultLang}
				description={metaDescription}
				index={metaIndexable}
				image={metaImage}
				keywords={metaKeywords}
				lat={metaLat}
				lang={lang}
				links={metaLinks}
				lng={metaLng}
				title={metaTitle}
			/>
			<ReactTracking codes={trackingCodes} />
			<DetectLayout />
		</>
	);
};

export default DetectPage;
