import React, { useContext, useEffect, useState, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { alpha } from "@material-ui/core/styles/";
import { theme as customTheme } from "./themes/project";
import DetectPage from "./helper/detectPage";
import ChangePage from "./helper/changePage";
import CSSProperties from "./utils/CSSProperties";
import MenuContext from "./context/menuContext";
import PageContext from "@micado-digital/react-ui/context/PageContext";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import ConsentData from "@micado-digital/react-ui/components/ConsentData";
import Ui from "@micado-digital/react-ui/components/Ui";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import {
	applyPolyfills as ialApplyPolyfills,
	defineCustomElements as ialDefineCustomElements
} from "@micado-digital/micado-ial/loader";
import "./css/index.css";

const theme = createTheme(customTheme);

const Core = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [pageData] = useContext(PageContext);
	let { lang = "de" } = pageData;

	return (
		<WebProfile key={lang}>
			<ConsentData lang="de">
				<MenuContext.Provider value={[menuOpen, setMenuOpen]}>
					<CssBaseline />
					<ChangePage />
					<Routes>
						<Route path="/*" element={<DetectPage />} />
					</Routes>
				</MenuContext.Provider>
			</ConsentData>
		</WebProfile>
	);
};

const App = () => {
	useEffect(() => {
		CSSProperties(theme, alpha);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<BrowserRouter>
					<Ui>
						<WebPageData>
							<Core />
						</WebPageData>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

ialApplyPolyfills().then(() => {
	ialDefineCustomElements(window);
});
