import React, { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import PageContext from "@PageContext";
import LayoutStandard from "../views/template/standard";
import Error404 from "../views/template/errors/404";

const DetectLayout = () => {
	const [pageData] = useContext(PageContext);
	const { layoutTag, layoutVariant, state } = pageData;

	if (isEmpty(pageData)) {
		return <></>;
	}

	// errors

	// eslint-disable-next-line default-case
	switch (state) {
		case 404:
			return <Error404 />;
	}

	switch (layoutTag) {
		case "standard":
		default:
			return <LayoutStandard variant={layoutVariant}></LayoutStandard>;
	}
};

export default DetectLayout;
