import React from "react";
import isEmpty from "lodash/isEmpty";
import makeStyles from "@material-ui/styles/makeStyles";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
	root: {
		background: "#FFFFFF",
		display: "grid",
		gridTemplateColumns: "30% 70%",
		gridTemplateRows: "repeat(8, auto)",
		margin: theme.spacing(0, -2, 0 - 2),
		padding: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			borderRadius: 8,
			gridTemplateColumns: "194px auto",
			margin: theme.spacing(0),
			padding: theme.spacing(3)
		},
		[theme.breakpoints.up("md")]: {
			gridTemplateColumns: "194px 1fr 1fr",
			padding: theme.spacing(2)
		}
	},
	image: {
		gridColumn: "1 / 2",
		gridRow: "1 / 4",
		margin: theme.spacing(0, 2, 2, 0),
		[theme.breakpoints.up("sm")]: {
			gridRow: "1 / 6",
			margin: theme.spacing(0, 3, 0, 0)
		}
	},
	name: {
		gridColumn: "2 / 3",
		gridRow: "1 / 2"
	},
	function: {
		gridColumn: "2 / 3",
		gridRow: "2 / 3"
	},
	phone: {
		alignItems: "center",
		display: "flex",
		gridColumn: "1 / 3",
		gridRow: "4 / 5",
		"& span": {
			marginLeft: theme.spacing(2.5)
		},
		[theme.breakpoints.up("sm")]: {
			gridColumn: "2 / 3",
			gridRow: "4 / 5"
		},
		[theme.breakpoints.up("md")]: {
			gridColumn: "3 / 4",
			gridRow: "1 / 2"
		}
	},
	mail: {
		gridColumn: "1 / 3",
		gridRow: "5 / 6",
		"& a": {
			color: theme.palette.text.primary,
			alignItems: "center",
			display: "inline-flex",
			transition: theme.transitions.create(["color"], {
				easing: theme.transitions?.easing?.easeInOut,
				duration: theme.transitions?.duration?.shorter
			})
		},
		"& span": {
			marginLeft: theme.spacing(2.5)
		},
		"& a:hover": {
			color: theme.palette.primary.main
		},
		[theme.breakpoints.up("sm")]: {
			gridColumn: "2 / 3",
			gridRow: "5 / 6"
		},
		[theme.breakpoints.up("md")]: {
			gridColumn: "3 / 4",
			gridRow: "2 / 3"
		}
	},
	description: {
		gridColumn: "1 / 3",
		gridRow: "6 / 7",
		marginTop: theme.spacing(1),
		[theme.breakpoints.up("sm")]: {
			marginTop: theme.spacing(2)
		},
		[theme.breakpoints.up("md")]: {
			gridColumn: "2 / 4",
			gridRow: "4 / 5"
		}
	},
	button: {
		gridColumn: "2 / 3",
		gridRow: "7 / 8",
		margin: theme.spacing(2, 0, 0, "auto"),
		[theme.breakpoints.up("md")]: {
			gridColumn: "3 / 4",
			gridRow: "5 / 6"
		}
	}
}));

const Employee = ({
	name,
	jobFunction,
	description,
	phone,
	mail,
	media,
	mediaPath,
	mediaFormats,
	pagename
}) => {
	const css = useStyles();

	return (
		<div className={clsx("employee", css.root)}>
			<div className={clsx("employee__image", css.image)}>
				{!isEmpty(media) ? (
					<ReactHTMLPicture
						media={media}
						path={mediaPath}
						webpFallback={true}
						{...mediaFormats}
					/>
				) : (
					<img
						src="/img/employee-fallback.jpg"
						alt="fallback"
						height="170"
						width="170"
						loading="lazy"
					></img>
				)}
			</div>
			{name && (
				<Typography
					className={clsx("employee__name", css.name)}
					component="p"
					dangerouslySetInnerHTML={{ __html: name }}
					variant="h5"
				/>
			)}
			{jobFunction && (
				<Typography
					className={clsx("employee__function", css.function)}
					component="p"
					dangerouslySetInnerHTML={{ __html: jobFunction }}
					variant="h6"
				/>
			)}
			{description && (
				<Typography
					className={clsx("employee__mail", css.description)}
					component="p"
					dangerouslySetInnerHTML={{ __html: description }}
					variant="body2"
				/>
			)}
			{phone && (
				<Typography
					className={clsx("employee__phone", css.phone)}
					component="div"
					variant="body1"
				>
					<ReactSVGIcon src={"/img/icons/phone.svg"} color="inherit" size={24} />
					<span>{phone}</span>
				</Typography>
			)}
			{mail && (
				<div className={clsx("employee__mail", css.mail)}>
					<Typography
						component="a"
						href={"mailto:" + mail}
						target="_blank"
						rel="noreferrer"
						variant="body1"
					>
						<ReactSVGIcon src={"/img/icons/mail.svg"} color="inherit" size={24} />
						<span>{mail}</span>
					</Typography>
				</div>
			)}
			{pagename && (
				<Button
					className={css.button}
					component="a"
					color="secondary"
					href={pagename}
					variant="contained"
					size="large"
				>
					Details
				</Button>
			)}
		</div>
	);
};

export default Employee;
