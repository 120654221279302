export const theme = {
	palette: {
		primary: {
			light: "#FFA998",
			main: "#F0856C",
			dark: "#B55948"
		},
		secondary: {
			light: "#70918D",
			main: "#355A55",
			dark: "#1D413C"
		},
		background: {
			footer: "#5B8975",
			header: "#355A55",
			button: "#F0856C",
			buttonHover: "#B55948",
			light: "#FFFFFF",
			dark: "#4B4F4A",
			menuMobileBackdropLayer1: "#D0D2D0",
			menuMobileBackdropLayer2: "#E5E5E3",
			menuMobileBackdropLayer3: "#FFFFFF"
		},
		bodyText: "#333333",
		link: {
			primary: "#F0856C",
			secondary: "#355A55"
		}
	},
	typography: {
		fontFamily: "'Rum-Sans', sans-serif",
		fontWeightLight: 300,
		fontWeightRegular: 300,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		body1: {
			fontFamily: "'Rum-Sans'",
			fontSize: 18,
			fontWeight: 300,
			lineHeight: 1.77
		},
		body2: {
			fontFamily: "'Rum-Sans'",
			fontSize: 16,
			fontWeight: 300,
			lineHeight: 1.5
		},
		h1: {
			color: "#333333 !important",
			fontFamily: "'Rum-Serif'",
			fontSize: "clamp(38px, 8vw, 64px)",
			fontWeight: 600,
			hyphens: "auto",
			textAlign: "center"
		},
		h2: {
			color: "#333333 !important",
			fontFamily: "'Rum-Serif'",
			fontSize: "clamp(34px, 8vw, 42px)",
			fontWeight: 600,
			hyphens: "auto",
			textAlign: "center"
		},
		h3: {
			color: "#333333 !important",
			fontFamily: "'Rum-Serif'",
			fontSize: "clamp(32px, 8vw, 36px)",
			fontWeight: 600,
			hyphens: "auto",
			textAlign: "left"
		},
		h4: {
			color: "#333333 !important",
			fontFamily: "'Rum-Serif'",
			textAlign: "left"
		},
		h5: {
			color: "#333333 !important",
			fontFamily: "'Rum-Serif'",
			textAlign: "left"
		},
		h6: {
			fontFamily: "'Rum-Serif'",
			fontSize: 18,
			fontWeight: 600,
			letterSpacing: "0.05em",
			textAlign: "left"
		},
		button: {
			fontFamily: "'Rum-Serif'",
			fontSize: 18,
			fontWeight: 600,
			letterSpacing: "0.05em",
			textTransform: "none"
		},
		smallText: {
			fontSize: 14
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				html: {
					"--palette-primary-main": "#F0856C",
					"--palette-secondary-main": "#355A55"
				}
			}
		},
		MuiButton: {
			root: {
				boxShadow: "none !important",
				height: 45
			},
			sizeSmall: {
				height: 32
			},
			sizeLarge: {
				height: 50
			}
		}
	},
	customTypography: {
		mainmenu: {
			mobile: {
				level1: {
					color: "black",
					fontFamily: "'Rum-Serif'",
					fontWeight: 600,
					fontSize: 22
				},
				level2: {
					fontFamily: "'Rum-Serif'",
					fontWeight: 600,
					fontSize: 18
				},
				level3: {
					fontWeight: 300,
					fontSize: 16
				}
			},
			desktop: {
				level1: {
					fontSize: 14,
					fontWeight: 700
				},
				level2: {
					fontSize: 16
				},
				level3: {
					fontSize: 14
				}
			}
		}
	}
};
