import React, { useEffect, lazy, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const ReactTeaserList01 = lazy(() =>
	import(
		/* webpackChunkName: "reactTeaserList01" */ "@micado-digital/react-teaser-list/ReactTeaserList01"
	)
);
const ReactSVGIcon = lazy(() =>
	import(/* webpackChunkName: "reactSVGIcon" */ "@micado-digital/react-svg-icon/ReactSVGIcon")
);

const useStyles = makeStyles((theme) => ({
	root: {
		bottom: theme.spacing(3),
		position: "absolute",
		zIndex: 2,
		"& .swiper-slide": {
			alignItems: "flex-end",
			display: "flex"
		},
		[theme.breakpoints.up("md")]: {
			bottom: theme.spacing(9)
		}
	},
	title: {
		color: "#FFFFFF !important",
		marginBottom: theme.spacing(1),
		textAlign: "left"
	},
	addition: {
		color: "#FFFFFF !important",
		fontFamily: "'Rum-Sans'",
		fontSize: "clamp(22px, 8vw, 28px)",
		marginBottom: theme.spacing(1),
		textAlign: "left"
	},
	button: {
		color: "#FFFFFF",
		fontSize: 18,
		padding: 0,
		"&:hover": {
			background: "none"
		}
	}
}));

const AnimationTeaser = ({ teaserURL }) => {
	const [data, setData] = useState();
	const css = useStyles();

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await axios.post(teaserURL, null);
				setData(result?.data);
			} catch (error) {
				setData({});
			}
		}

		fetchData();
	}, [teaserURL]);

	let breakpoints = {
		0: {
			slidesPerView: 1,
			spaceBetween: 24
		}
	};

	if (data?.items?.length > 0) {
		document.body.classList.add("animation-teaser");
	}

	let items = null;

	if (data && data?.items?.length > 0) {
		items = data?.items?.map(({ id, link, title, addition, linkTarget }, i) => {
			return (
				<div className={clsx("animation-teaser", css.teaserItem)} key={id}>
					<a href={link} target={linkTarget}>
						{addition && (
							<Typography
								className={clsx("animation-teaser__addition", css.addition)}
								color="textPrimary"
								dangerouslySetInnerHTML={{ __html: addition }}
								variant="h4"
								component="p"
							/>
						)}
						<Typography
							className={clsx("animation-teaser__title", css.title)}
							color="textPrimary"
							dangerouslySetInnerHTML={{ __html: title }}
							component="p"
							variant="h2"
						/>
						<Button
							className={css.button}
							color="secondary"
							variant="text"
							endIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={12} />}
							size="large"
						>
							Mehr Erfahren
						</Button>
					</a>
				</div>
			);
		});
	}

	return items ? (
		<Container className={clsx("animation-teaser-list", css.root)} maxWidth={false}>
			<Container maxWidth="lg">
				<ReactTeaserList01
					autoplay={true}
					delay={7000}
					headline=""
					loop={false}
					sliderBreakpoints={breakpoints}
					showPagination={false}
					showNavigation={false}
					spacing={3}
					speed={2000}
					variant="slider"
				>
					{items}
				</ReactTeaserList01>
			</Container>
		</Container>
	) : (
		<></>
	);
};

AnimationTeaser.propTypes = {
	teaserURL: PropTypes.string.isRequired
};

export default AnimationTeaser;
