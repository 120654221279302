import clsx from "clsx";
import PropTypes from "prop-types";
import TeaserItem from "../teaserItem/index.jsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	headline: {
		marginBottom: theme.spacing(4),
		textAlign: "center"
	},
	text: {
		textAlign: "center"
	},
	container: {
		marginTop: theme.spacing(10),
		[theme.breakpoints.up("sm")]: {
			display: "grid",
			gridGap: theme.spacing(4),
			gridTemplateColumns: "1fr 1fr"
		},
		[theme.breakpoints.up("md")]: {
			gridTemplateColumns: "1fr 1fr 1fr"
		}
	}
}));

const TeaserList = ({ title, text, items }) => {
	const { REACT_APP_PATH } = process.env;
	const css = useStyles();

	return (
		<div className={clsx("teaser-list")}>
			{title && (
				<Typography
					className={clsx("teaser-list__headline", css.headline)}
					dangerouslySetInnerHTML={{ __html: title }}
					variant="h3"
				></Typography>
			)}
			{text && (
				<Typography
					className={clsx("teaser-list__text", css.text)}
					dangerouslySetInnerHTML={{ __html: text }}
					variant="body2"
				></Typography>
			)}
			<div className={clsx("teaser-list__container", css.container)}>
				{items?.map(
					({ id, addition, link, linkTarget, linkRel, media, title, text, from, to }, i) => {
						return (
							<TeaserItem
								key={id}
								addition={addition}
								link={link}
								linkTarget={linkTarget}
								linkRel={linkRel}
								media={media?.[0]}
								mediaFormats={{ xs: "teaser-subformat" }}
								mediaPath={REACT_APP_PATH}
								title={title}
								text={text}
								dateFrom={from}
								dateTo={to}
							></TeaserItem>
						);
					}
				)}
			</div>
		</div>
	);
};

TeaserList.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	items: PropTypes.array.isRequired
};

export default TeaserList;
