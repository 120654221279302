import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	a: {
		borderBottom: "1px solid rgba(255,255,255,0.3)",
		color: theme.palette.common.white,
		display: "block",
		padding: theme.spacing(2, 0),
		transition: "border-bottom 300ms ease",
		"&:first-child": {
			borderTop: "1px solid rgba(255,255,255,0.3)"
		},
		"&:hover": {
			borderBottom: "1px solid rgba(255,255,255,0.7)"
		}
	},
	aFreiraum: {
		borderBottom: "1px solid rgba(46,43,43,0.3)",
		color: "#2E2B2B",
		"&:first-child": {
			borderTop: "1px solid rgba(46,43,43,0.3)"
		},
		"&:hover": {
			borderBottom: "1px solid rgba(46,43,43,0.7)"
		}
	}
}));

const Footermenu = ({ data, variant }) => {
	const css = useStyles();

	return (
		<div>
			{data?.items?.map(({ id, link, target, title }, i) => {
				return (
					<a
						className={clsx(css.a, { [css.aFreiraum]: variant === "freiraum" })}
						href={link}
						target={target}
						key={id}
					>
						{title}
					</a>
				);
			})}
		</div>
	);
};

Footermenu.propTypes = {
	data: PropTypes.object.isRequired
};

export default Footermenu;
