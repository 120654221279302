import React, { useContext, lazy } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import Container from "@material-ui/core/Container";
import PageContext from "@PageContext";
import TeaserList from "../../../components/teaserList";
import ImageTextButton from "../../../components/imageTextButton";
import Quote from "../../../components/quote";
import Employee from "../../../components/employee";
import Organigramm from "../../../components/organigramm";

const ReactHeadline01 = lazy(() =>
	import(
		/* webpackChunkName: "reactHeadline01" */ "@micado-digital/react-headline/ReactHeadline01"
	)
);
const Connector = lazy(() =>
	import(/* webpackChunkName: "reactConnector" */ "@micado-digital/react-form/Connector")
);
const Form = lazy(() =>
	import(/* webpackChunkName: "reactForm" */ "@micado-digital/react-form")
);
const ReactList01 = lazy(() =>
	import(/* webpackChunkName: "reactList01" */ "@micado-digital/react-list/ReactList01")
);
const ReactText01 = lazy(() =>
	import(/* webpackChunkName: "reactText01" */ "@micado-digital/react-text/ReactText01")
);
const ReactSingleImage02 = lazy(() =>
	import(
		/* webpackChunkName: "reactSingleImage02" */ "@micado-digital/react-singleimage/ReactSingleImage02"
	)
);
const ReactImageText01 = lazy(() =>
	import(
		/* webpackChunkName: "reactImageText01" */ "@micado-digital/react-imagetext/ReactImageText01"
	)
);
const ReactReferences01 = lazy(() =>
	import(
		/* webpackChunkName: "reactReferences01" */ "@micado-digital/react-references/ReactReferences01"
	)
);
const ReactGallery01 = lazy(() =>
	import(
		/* webpackChunkName: "reactGallery01" */ "@micado-digital/react-gallery/ReactGallery01"
	)
);
const DropDown = lazy(() =>
	import(/* webpackChunkName: "reactDropDown" */ "../../../components/dropdown")
);
const ReactTable01 = lazy(() =>
	import(/* webpackChunkName: "reactTable01" */ "@micado-digital/react-table/ReactTable01")
);
const ReactFile01 = lazy(() =>
	import(/* webpackChunkName: "reactFile01" */ "@micado-digital/react-file/ReactFile01")
);
const ReactSingleTeaser01 = lazy(() =>
	import(
		/* webpackChunkName: "reactSingleTeaser01" */ "@micado-digital/react-singleteaser/ReactSingleTeaser01"
	)
);
const ReactVideo01 = lazy(() =>
	import(/* webpackChunkName: "reactVideo01" */ "@micado-digital/react-video/ReactVideo01")
);
const ReactTeaser01 = lazy(() =>
	import(/* webpackChunkName: "reactTeaser01" */ "@micado-digital/react-teaser/ReactTeaser01")
);
const ReactTeaserList01 = lazy(() =>
	import(
		/* webpackChunkName: "reactTeaserList01" */ "@micado-digital/react-teaser-list/ReactTeaserList01"
	)
);
const ReactFacts01 = lazy(() =>
	import(/* webpackChunkName: "reactFacts01" */ "@micado-digital/react-facts/ReactFacts01")
);
const ReactHTML = lazy(() =>
	import(/* webpackChunkName: "ReactHTML" */ "@micado-digital/react-html/ReactHTML")
);
const ReactHTMLExtended = lazy(() =>
	import(/* webpackChunkName: "ReactHTML" */ "@micado-digital/react-html/ReactHTML02")
);
const Logobar = lazy(() =>
	import(/* webpackChunkName: "Logobar" */ "../../../components/logobar")
);

const ContentElements = () => {
	const [pageData] = useContext(PageContext);

	if (isEmpty(pageData)) return <></>;

	const elements = pageData?.elements;

	if (!elements) return <></>;

	let isInDropdown = false;
	let isDropdownStartTag = false;
	let isDropdownEndTag = false;
	let currentDropdown = null;

	let newElements = cloneDeep(elements);

	newElements = newElements.filter((item) => {
		if (item.tag === "basic-dropdown") {
			if (item.option === 1) {
				currentDropdown = null;
				isDropdownStartTag = false;
				isDropdownEndTag = true;
				isInDropdown = false;
			} else {
				currentDropdown = item;
				isDropdownStartTag = true;
				isDropdownEndTag = false;
				isInDropdown = true;
			}
		} else {
			isDropdownStartTag = false;
			isDropdownEndTag = false;
		}

		if (isInDropdown && !isDropdownStartTag && currentDropdown) {
			if (currentDropdown._subItems) {
				currentDropdown._subItems.push(item);
			} else {
				currentDropdown._subItems = [item];
			}
		} else if (!isDropdownEndTag) {
			return item;
		}

		return null;
	});

	const parsed = newElements.map((element) => {
		return (
			<React.Fragment key={element.id}>
				{element?.anchor?.visible && <span id={element.anchor.tag} />}
				{parseElements(element)}
			</React.Fragment>
		);
	});

	return <>{parsed}</>;
};

const parseElements = (element) => {
	const { tag /*_subItems*/, anchor } = element;
	const { REACT_APP_PATH } = process.env;

	switch (tag) {
		case "basic-headline":
			return (
				<Container
					className={clsx(
						"container-headline container-margin container-headline-" +
							((element?.variant || 0) + 1)
					)}
					maxWidth="lg"
				>
					<ReactHeadline01 text={element?.text} variant={element?.variant}></ReactHeadline01>
				</Container>
			);

		case "basic-text":
			return (
				<Container className="container-text container-margin" maxWidth="lg">
					<ReactText01 text={element?.text}></ReactText01>
				</Container>
			);

		case "basic-list":
			if (!element?.textcontent) {
				return <></>;
			}

			return (
				<Container className="container-list container-margin" maxWidth="lg">
					<ReactList01
						textcontent={element?.textcontent}
						title={element?.title}
						variant={element?.variant}
					></ReactList01>
				</Container>
			);

		case "basic-singleimage":
			return (
				<Container
					className={clsx(
						"container-singleimage container-margin",
						element?.title ? "has-title" : ""
					)}
					maxWidth="xl"
				>
					<ReactSingleImage02
						addition={element?.addition}
						option2={element?.option2}
						media={element?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							sm: "basic-singleimage"
						}}
						reference={element?.reference}
						title={element?.title}
						text={element?.text}
					></ReactSingleImage02>
				</Container>
			);

		case "basic-imagetext":
			return (
				<Container className="container-imagetext container-margin" maxWidth="lg">
					<ReactImageText01
						addition2={element?.addition2}
						option={element?.option}
						media={element?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-imagetext"
						}}
						reference={element?.reference}
						title={element?.title}
						text={element?.text}
					></ReactImageText01>
				</Container>
			);

		case "basic-links":
			if (!element?.elements) {
				return <></>;
			}

			return (
				<Container className="container-references container-margin" maxWidth={false}>
					<Container maxWidth="lg">
						<ReactReferences01
							elements={element?.elements}
							title={element?.title}
							hasIcon={false}
						></ReactReferences01>
					</Container>
				</Container>
			);

		case "basic-gallery":
			if (!element?.elements) {
				return <></>;
			}

			return (
				<Container className="container-gallery container-margin" maxWidth="lg">
					<ReactGallery01
						elements={element?.elements}
						mediaFormats={{
							xs: "basic-gallery-thumb"
						}}
						overlayMediaFormats={{
							xs: "basic-gallery"
						}}
					></ReactGallery01>
				</Container>
			);

		case "basic-download":
			if (!element?.media) {
				return <></>;
			}

			return (
				<Container className="container-download container-margin" maxWidth="lg">
					<ReactFile01 media={element?.media} title={element?.title}></ReactFile01>
				</Container>
			);

		case "basic-singleteaser": {
			const { id, teaser, text, title, variant, teaser: { media } = {} } = element;

			const teaserData = {
				id: id,
				link: teaser?.link,
				media: teaser?.media,
				text: text ? text : teaser?.text,
				title: title ? title : teaser?.title
			};

			return (
				<Container className="container-singleteaser container-margin" maxWidth="lg">
					<ReactSingleTeaser01
						buttonLabel={"Mehr erfahren"}
						teaser={teaserData}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser"
						}}
						variant={variant}
						icon={"/img/icons/chevron-right.svg"}
						iconSize={12}
					></ReactSingleTeaser01>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			const { elements, id, textcontent, variant } = element;

			const title = textcontent?.items?.find((item) => item?.name === "Title")?.text;
			const text = textcontent?.items?.find((item) => item?.name === "Text")?.text;
			const link = textcontent?.items?.find((item) => item?.name === "URL")?.text;
			const media = elements?.find((e) => e.media)?.media?.[0];

			const teaserData = {
				id: id,
				link: link,
				linkTarget: "_blank",
				media: media,
				text: text,
				title: title
			};

			return (
				<Container className="container-singleteaser container-margin" maxWidth="lg">
					<ReactSingleTeaser01
						buttonLabel={"Mehr erfahren"}
						teaser={teaserData}
						media={media}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser"
						}}
						variant={variant}
						icon={"/img/icons/chevron-right.svg"}
						iconSize={12}
					></ReactSingleTeaser01>
				</Container>
			);
		}

		case "basic-video":
			return (
				<Container className="container-video container-margin" maxWidth="lg">
					<ReactVideo01
						title={element?.title}
						text={element?.text}
						media={element?.media}
					></ReactVideo01>
				</Container>
			);

		case "basic-table":
			return (
				<Container className="container-table container-margin" maxWidth="lg">
					<ReactTable01
						key={element?.id}
						addition={element?.addition}
						title={element?.title}
						items={element?.textcontent?.items}
					></ReactTable01>
				</Container>
			);

		case "basic-teaser":
			if (element?.variant === 1) {
				let breakpoints = {
					0: {
						slidesPerView: 1.2,
						spaceBetween: 24
					},
					500: {
						slidesPerView: 1.7,
						spaceBetween: 24
					},
					700: {
						slidesPerView: 2.2,
						spaceBetween: 24
					},
					800: {
						slidesPerView: 2.7,
						spaceBetween: 24
					},
					980: {
						slidesPerView: 3,
						spaceBetween: 32
					}
				};

				return (
					<Container
						className="container-teasergroup container-teasergroup-slider container-margin"
						maxWidth={false}
					>
						<Container maxWidth="lg">
							<ReactTeaserList01
								autoplay={false}
								delay={5000}
								headline={element?.title}
								button={{
									link: element?.reference?.pageName,
									linkTarget: "_blank",
									linkTitle: "Aktuelle Neuigkeiten",
									icon: "/img/icons/chevron-right.svg"
								}}
								loop={false}
								navNext="/img/icons/chevron-right.svg"
								navPrev="/img/icons/chevron-left.svg"
								sliderBreakpoints={breakpoints}
								showPagination={false}
								showNavigation={true}
								spacing={3}
								speed={1000}
								variant="slider"
							>
								{element?.teasergroup?.items?.map(
									({ id, link, media, title, text, linkTarget, linkRel, from, to }, i) => {
										const date = {
											dateFrom: from,
											dateTo: to
										};
										return (
											<ReactTeaser01
												button="Mehr erfahren"
												buttonIcon="/img/icons/chevron-right.svg"
												buttonSize="large"
												buttonVariant="text"
												date={date}
												key={id}
												link={link}
												linkTarget={linkTarget}
												linkRel={linkRel}
												linkElement={true}
												media={media?.[0]}
												mediaFormats={{
													xs: "basic-teaser"
												}}
												mediaPath={REACT_APP_PATH}
												target={linkTarget}
												text={text}
												title={title}
											/>
										);
									}
								)}
							</ReactTeaserList01>
						</Container>
					</Container>
				);
			} else {
				return (
					<Container
						className="container-teasergroup-custom container-margin"
						maxWidth={false}
					>
						<Container maxWidth="lg">
							<TeaserList
								title={element?.title}
								text={element?.text}
								items={element?.teasergroup?.items}
							></TeaserList>
						</Container>
					</Container>
				);
			}

		case "basic-form":
			const { id, elements } = element;

			return (
				<Container className="container-form container-margin" maxWidth={false}>
					<Container maxWidth="lg">
						<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST">
							<Connector formElements={elements} formID={id} />
						</Form>
					</Container>
				</Container>
			);

		case "extension-imagetext-button":
			let media = null;
			let link = null;
			let headline = null;
			let buttonLabel = null;
			let text = null;

			element?.elements?.filter((item) => {
				if (item.tag === "media") {
					return (media = item?.media?.[0]);
				}
				if (item.tag === "link") {
					return (link = item?.teaser?.link);
				}

				return null;
			});

			element?.textcontent?.items?.filter((item) => {
				if (item.name === "headline") {
					return (headline = item?.text);
				}
				if (item.name === "buttonLabel") {
					return (buttonLabel = item?.text);
				}
				if (item.name === "text") {
					return (text = item?.text);
				}

				return null;
			});

			return (
				<Container className="container-imagetext-button container-margin" maxWidth="lg">
					<ImageTextButton
						media={media}
						link={link}
						icon={"/img/icons/chevron-right.svg"}
						iconSize={12}
						headline={headline}
						buttonLabel={buttonLabel}
						text={text}
					></ImageTextButton>
				</Container>
			);

		case "extension-quote": {
			const { elements = [] } = element;
			let quoteText = null;
			let quoteAuthor = null;

			element?.textcontent?.items?.filter((item) => {
				if (item.name === "valQuote") {
					return (quoteText = item?.text);
				}

				if (item.name === "valAuthor") {
					return (quoteAuthor = item?.text);
				}

				return null;
			});

			return (
				<Container className="container-quote container-margin" maxWidth="lg">
					<Quote
						text={quoteText}
						author={quoteAuthor}
						media={elements[0]?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser-subformat"
						}}
					></Quote>
				</Container>
			);
		}

		case "extension-facts":
			let factsHeadline = null;

			element?.textcontent?.items?.filter((item) => {
				if (item.name === "headline") {
					return (factsHeadline = item?.text);
				}

				return null;
			});

			return (
				<Container className="container-facts container-margin" maxWidth={false}>
					<Container maxWidth="lg">
						<ReactFacts01 items={element?.elements} headline={factsHeadline}></ReactFacts01>
					</Container>
				</Container>
			);

		case "extension-employee": {
			let name = null;
			let jobFunction = null;
			let description = null;
			let phone = null;
			let mail = null;
			let pagename = null;
			let media = null;

			element?.textcontent?.items?.filter((item) => {
				if (item.name === "name") {
					return (name = item?.text);
				}
				if (item.name === "function") {
					return (jobFunction = item?.text);
				}
				if (item.name === "description") {
					return (description = item?.text);
				}
				if (item.name === "phone") {
					return (phone = item?.text);
				}
				if (item.name === "mail") {
					return (mail = item?.text);
				}

				return null;
			});

			element?.elements?.filter((item) => {
				if (item.media) {
					return (media = item?.media?.[0]);
				}
				if (item.reference) {
					return (pagename = item?.reference?.pageName);
				}

				return null;
			});

			return (
				<Container className="container-employee container-margin" maxWidth="lg">
					<Employee
						name={name}
						jobFunction={jobFunction}
						description={description}
						phone={phone}
						mail={mail}
						media={media}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "extension-employee"
						}}
						pagename={pagename}
					></Employee>
				</Container>
			);
		}

		case "extension-organigramm":
			return (
				<Container className="container-organigramm container-margin" maxWidth="lg">
					<Organigramm
						media={element?.elements[0]?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "extension-organigramm"
						}}
					></Organigramm>
				</Container>
			);

		case "basic-dropdown": {
			return (
				<Container
					key={element?.id}
					maxWidth="lg"
					className="container-dropdown container-margin"
				>
					<DropDown data={element}>{map(element?._subItems, parseElements)}</DropDown>
				</Container>
			);
		}

		case "extension-logos": {
			return (
				<Container anchor={anchor} key={element?.id}>
					<Logobar />
				</Container>
			);
		}

		case "basic-html": {
			const { id, text } = element;

			return (
				<Container anchor={anchor} key={id}>
					<ReactHTML key={id} html={text} />
				</Container>
			);
		}

		case "basic-html-extended": {
			const { id, textcontent } = element;

			const identifier = textcontent?.items?.find(
				(item) => item?.name === "valIdentifier"
			)?.text;
			const externalScript = textcontent?.items?.find(
				(item) => item?.name === "valExternalScript"
			)?.text;
			const html = textcontent?.items?.find((item) => item?.name === "valHTML")?.text;
			const script = textcontent?.items?.find((item) => item?.name === "valScript")?.text;

			return (
				<Container key={id} anchor={anchor}>
					<ReactHTMLExtended
						externalScript={externalScript}
						html={html}
						id={identifier ?? id}
						script={script}
					/>
				</Container>
			);
		}

		default:
			console.info(element);
			return null;
	}
};

export default ContentElements;
