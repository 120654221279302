import React from "react";

const Image = (props) => {
	const {
		filename,
		alt = "",
		height,
		loading = "lazy",
		width,
		itemStyle,
		disableCover
	} = props;

	if (!filename) return "";

	const styles = {
		display: "block",
		height: "auto",
		width: "100%"
	};

	if (!disableCover) {
		styles.height = "100%";
		styles.objectFit = "cover";
	}

	return (
		<img
			className={itemStyle}
			alt={alt}
			height={height}
			loading={loading}
			src={filename}
			style={styles}
			width={width}
		/>
	);
};

export default Image;
