import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(5),
		[theme.breakpoints.up("md")]: {
			marginRight: theme.spacing(2)
		},
		"&:last-child": {
			marginRight: theme.spacing(0)
		}
	},
	topWrapper: {
		marginBottom: theme.spacing(2)
	},
	title: {
		fontWeight: theme.typography.fontWeightBold
	},
	link: {
		color: theme.palette.common.white,
		display: "table",
		"&:hover": {
			textDecoration: "underline"
		}
	},
	openingTimes: {
		marginTop: theme.spacing(2),
		"& strong": {
			textDecoration: "underline"
		},
		"& span": {
			display: "block",
			fontWeight: 600,
			lineHeight: 1.5
		}
	}
}));

const Contact = ({ data, variant }) => {
	const css = useStyles();

	if (variant === "freiraum") {
		return (
			<div className={clsx("contact-freiraum" + css.root)}>
				<div className={clsx("top-wrapper", css.topWrapper)}>
					<p className={clsx("title", css.title)}>FREIRAUM @Interalp</p>
					<p className={clsx("street")}>Inngasse 5</p>
					<p className={clsx("city")}>6330 Kufstein</p>
				</div>
				<a className={clsx("phone", css.link)} href="tel:+43537222490">
					0043 5372 22490
				</a>
				<a className={clsx("mail01", css.link)} href="mailto:info@freiraum-kufstein.at">
					info@freiraum-kufstein.at
				</a>
			</div>
		);
	}

	if (variant === "grenzenlos") {
		return (
			<div className={clsx("contact-grenzenlos" + css.root)}>
				<div className={clsx("top-wrapper", css.topWrapper)}>
					<p className={clsx("title", css.title)}>Grenzenlos CoWorking Kössen</p>
					<p className={clsx("street")}>Dorf 4</p>
					<p className={clsx("city")}>6345 Kössen</p>
				</div>
				<a className={clsx("phone", css.link)} href="tel:+436641823926">
					0043 664 18 23 926
				</a>
				<a className={clsx("mail01", css.link)} href="mailto:info@grenzenlos-coworking.at">
					info@grenzenlos-coworking.at
				</a>
			</div>
		);
	}

	if (variant === "bio") {
		return (
			<div className={clsx("contact-bio" + css.root)}>
				<div className={clsx("top-wrapper", css.topWrapper)}>
					<p className={clsx("title", css.title)}>Bio Käserei Walchsee</p>
					<p className={clsx("street")}>Moosen 7</p>
					<p className={clsx("city")}>6344 Walchsee</p>
				</div>
				<a className={clsx("phone", css.link)} href="tel:+4353745296">
					Tel. Büro: +43 5374 5296
				</a>
				<a className={clsx("phone", css.link)} href="tel:+435374529612">
					Tel. Käseladen: +43 5374 5296 12
				</a>
				<a className={clsx("mail01", css.link)} href="mailto:verkauf@biokaeserei-walchsee.at">
					verkauf@biokaeserei-walchsee.at
				</a>
				<a className={clsx("mail02", css.link)} href="mailto:info@biokaeserei-walchsee.at">
					info@biokaeserei-walchsee.at
				</a>
			</div>
		);
	}

	return data?.map(({ id, title, street, city, zip, phone, mail01, mail02 }, index) => {
		return (
			<div className={clsx("contact-" + index, css.root)} key={id}>
				<div className={clsx("top-wrapper", css.topWrapper)}>
					{title && (
						<p className={clsx("title", css.title)}>
							{variant === "egen" ? "Innovations.Raum Kufstein eGen" : title}
						</p>
					)}
					{street && <p className={clsx("street")}>{street}</p>}
					{street && (
						<p className={clsx("city")}>
							{zip} {city}
						</p>
					)}
				</div>
				{phone && (
					<a
						className={clsx("phone", css.link)}
						href={clsx("tel:" + phone.replace(/\D/g, ``))}
					>
						{phone}
					</a>
				)}
				{mail01 && (
					<a className={clsx("mail01", css.link)} href={clsx("mailto:" + mail01)}>
						{mail01}
					</a>
				)}
				{mail02 && (
					<a className={clsx("mail02", css.link)} href={clsx("mailto:" + mail02)}>
						{mail02}
					</a>
				)}
				{(variant === "inno" || variant === "egen") && (
					<div className={css.openingTimes}>
						<strong>Öffnungszeiten Rezeption:</strong>
						<span>Mo. bis Do.: 9 – 17 Uhr</span>
						<span>Fr.: 9 – 14 Uhr</span>
					</div>
				)}
			</div>
		);
	});
};

Contact.propTypes = {
	data: PropTypes.array.isRequired
};

export default Contact;
