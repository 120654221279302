import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex"
	},
	a: {
		color: theme.palette.common.white,
		display: "flex",
		fontSize: theme.typography.smallText.fontSize,
		margin: theme.spacing(0.5, 2, 0.5, 0),
		"&:hover": {
			textDecoration: "underline"
		},
		"&:last-child": {
			marginRight: theme.spacing(0)
		}
	}
}));

const getIconFilename = (type) => {
	switch (type) {
		case "facebook":
			return "/img/icons/facebook.svg";
		case "instagram":
			return "/img/icons/instagram.svg";
		case "twitter":
			return "/img/icons/twitter.svg";
		case "pinterest":
			return "/img/icons/pinterest.svg";
		case "youtube":
			return "/img/icons/youtube.svg";
		case "vimeo":
			return "/img/icons/vimeo.svg";
		case "flickr":
			return "/img/icons/flickr.svg";
		default:
			return "";
	}
};

const SocialLinks = ({ data }) => {
	const css = useStyles();

	return (
		<div className={css.root}>
			{data?.map(({ title, type, url }, i) => {
				return (
					<a className={clsx(css.a)} href={url} key={type} target="_blank" rel="noreferrer">
						<ReactSVGIcon
							className={clsx(title)}
							size={24}
							src={getIconFilename(type)}
						></ReactSVGIcon>
					</a>
				);
			})}
		</div>
	);
};

SocialLinks.propTypes = {
	data: PropTypes.array.isRequired
};

export default SocialLinks;
