import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	image: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: theme.spacing(3),
		"& img": {
			borderRadius: "50%",
			margin: "0 auto",
			width: "auto"
		}
	},
	title: {
		marginBottom: theme.spacing(3)
	},
	textBox: {
		padding: theme.spacing(0, 0, 0, 0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(0, 0, 0, 3)
		},
		[theme.breakpoints.up("md")]: {
			padding: theme.spacing(0, 0, 0, 9)
		}
	},
	textBoxLeft: {
		padding: theme.spacing(0, 3, 0, 0)
	},
	button: {
		background: theme.palette.background.button,
		fontSize: 18,
		marginTop: theme.spacing(5),
		"&:hover": {
			background: theme.palette.background.buttonHover
		}
	}
}));

const ImageTextButton = ({
	headline,
	media,
	option: alignment = 0,
	text,
	icon,
	iconSize,
	buttonLabel,
	link
}) => {
	const { REACT_APP_PATH } = process.env;
	const css = useStyles();

	return (
		<Grid className={clsx("imagetext-button", css.root)} container>
			<Grid
				className={clsx("imagetext-button__image", css.image)}
				item
				xs={12}
				sm={4}
				style={{ order: alignment }}
			>
				<ReactHTMLPicture
					media={media}
					path={REACT_APP_PATH}
					webpFallback={true}
					xs="extension-imagetext-button"
				/>
			</Grid>
			<Grid
				className={clsx("imagetext-button__content", {
					[css.textBox]: true,
					[css.textBoxLeft]: alignment === 1
				})}
				item
				xs={12}
				sm={8}
			>
				<Typography
					className={clsx("imagetext-button__title", css.title)}
					color="textPrimary"
					dangerouslySetInnerHTML={{ __html: headline }}
					variant="h3"
				/>
				<Typography
					className="imagetext-button__text"
					component="div"
					dangerouslySetInnerHTML={{ __html: text }}
				/>
				{link && (
					<Button
						className={css.button}
						component="a"
						color="secondary"
						href={link}
						variant="contained"
						endIcon={icon && <ReactSVGIcon src={icon} size={iconSize} />}
						size="large"
					>
						{buttonLabel}
					</Button>
				)}
			</Grid>
		</Grid>
	);
};

ImageTextButton.propTypes = {
	media: PropTypes.object.isRequired,
	option: PropTypes.object,
	text: PropTypes.string,
	icon: PropTypes.string,
	iconSize: PropTypes.number,
	buttonLabel: PropTypes.string,
	link: PropTypes.string
};

export default ImageTextButton;
