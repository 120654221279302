import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import Image from "../../../utils/image";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "block"
	},
	rootHasAnchorMenu: {
		"& > a": {
			[theme.breakpoints.down("md")]: {
				height: "64px !important",
				padding: "0 8px !important",
				width: "auto !important",
				"& img": {
					objectFit: "contain !important",
					maxHeight: 58
				}
			}
		}
	},
	logo: {
		alignItems: "center",
		boxSizing: "content-box",
		display: "flex",
		filter: "drop-shadow(0px 0px 16px rgba(0,0,0,0.2))",
		height: "auto",
		maxWidth: 100,
		padding: 0,
		transition: "max-width 0.2s ease-in-out !important",
		width: "100%",
		[theme.breakpoints.up("md")]: {
			maxWidth: 150
		}
	},
	logoFreiraum: {
		alignItems: "center",
		boxSizing: "content-box",
		display: "flex",
		height: 105,
		paddingTop: 16,
		width: 70,
		transition: "padding-top 0.2s ease-in-out",
		[theme.breakpoints.up("md")]: {
			height: 164,
			paddingTop: 24,
			width: 109
		}
	},
	logoGrenzenlos: {
		alignItems: "center",
		background: "#FFFFFF",
		boxSizing: "content-box",
		display: "flex",
		height: 68,
		padding: theme.spacing(2, 3),
		width: 100,
		transition: theme.transitions.create(["height", "padding", "width"], {
			easing: theme.transitions?.easing?.easeInOut,
			duration: theme.transitions?.duration?.shorter
		}),
		[theme.breakpoints.up("md")]: {
			height: 123,
			padding: theme.spacing(3, 4),
			width: 180
		}
	},
	logoBio: {
		alignItems: "center",
		background: "#FFFFFF",
		boxSizing: "content-box",
		display: "flex",
		height: 67,
		padding: theme.spacing(1.5, 2),
		width: 100,
		transition: theme.transitions.create(["height", "padding", "width"], {
			easing: theme.transitions?.easing?.easeInOut,
			duration: theme.transitions?.duration?.shorter
		}),
		[theme.breakpoints.up("md")]: {
			height: 121,
			padding: theme.spacing(2.5),
			width: 180
		}
	}
}));

const Logo = ({ currentURL, hasAnchorMenu, layoutVariant }) => {
	const css = useStyles();

	if (layoutVariant === "freiraum") {
		return (
			<div
				className={clsx("logo-freiraum", css.root, { [css.rootHasAnchorMenu]: hasAnchorMenu })}
			>
				<a className={css.logoFreiraum} href={currentURL}>
					<Image
						filename="/img/logo-freiraum.png"
						alt="Freiraum@Interalp"
						loading="eager"
						height="164"
						width="109"
						disableCover
					/>
				</a>
			</div>
		);
	}

	if (layoutVariant === "grenzenlos") {
		return (
			<div
				className={clsx("logo-grenzenlos", css.root, {
					[css.rootHasAnchorMenu]: hasAnchorMenu
				})}
			>
				<a className={css.logoGrenzenlos} href={currentURL}>
					<Image
						filename="/img/logo-grenzenlos.png"
						alt="Grenzenlos Coworking Kössen"
						loading="eager"
						height="123"
						width="180"
						disableCover
					/>
				</a>
			</div>
		);
	}

	if (layoutVariant === "bio") {
		return (
			<div className={clsx("logo-bio", css.root, { [css.rootHasAnchorMenu]: hasAnchorMenu })}>
				<a className={css.logoBio} href={currentURL}>
					<Image
						filename="/img/logo-bio.png"
						alt="Bio Käserei"
						loading="eager"
						height="121"
						width="180"
						disableCover
					/>
				</a>
			</div>
		);
	}

	if (layoutVariant === "inno") {
		return (
			<div className={clsx("logo", css.root, { [css.rootHasAnchorMenu]: hasAnchorMenu })}>
				<a className={css.logo} href={currentURL}>
					<Image
						filename="/img/logo-inno.svg"
						alt="Coworkingspace Kufstein"
						loading="eager"
						height="77"
						width="181"
						disableCover
					/>
				</a>
			</div>
		);
	}

	if (layoutVariant === "egen") {
		return (
			<div className={clsx("logo", css.root, { [css.rootHasAnchorMenu]: hasAnchorMenu })}>
				<a className={css.logo} href={currentURL}>
					<Image
						filename="/img/logo-inno.svg"
						alt="Coworkingspace Kufstein"
						loading="eager"
						height="77"
						width="181"
						disableCover
					/>
				</a>
			</div>
		);
	}

	return <></>;
};

export default Logo;
