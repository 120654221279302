import clsx from "clsx";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, 0, 5),
		textAlign: "center",
		"&:hover": {
			"& .teaser-item__title": {
				color: theme.palette.link.secondary
			},
			"& img": {
				filter: "blur(2px)",
				transform: "scale(1.05)"
			}
		}
	},
	a: {
		color: "inherit",
		display: "block"
	},
	aNoClick: {
		pointerEvents: "none"
	},
	imageWrapper: {
		borderRadius: "50%",
		display: "inline-block",
		marginBottom: theme.spacing(3.5),
		overflow: "hidden",
		"& img": {
			borderRadius: "50%",
			display: "block",
			transition: "filter 300ms ease, transform 300ms ease"
		}
	},
	fallback: {
		alignItems: "center",
		display: "flex",
		background: "#dbdbdb",
		height: 160,
		justifyContent: "center",
		borderRadius: "50%",
		padding: 20,
		width: 160,
		"& img": {
			display: "block",
			width: "100%"
		}
	},
	title: {
		color: theme.palette.link.primary,
		marginBottom: theme.spacing(1.5),
		textAlign: "center",
		transition: "color 300ms ease"
	},
	date: {
		display: "block",
		marginBottom: theme.spacing(1)
	}
}));

const TeaserItem = ({
	title,
	link,
	linkTarget,
	linkRel,
	media,
	text,
	dateFrom,
	dateTo,
	mediaFormats,
	mediaPath
}) => {
	const css = useStyles();

	let date = "";
	const options = { year: "numeric", month: "2-digit", day: "2-digit" };

	if (dateFrom) {
		dateFrom = new Date(dateFrom).toLocaleDateString("de-DE", options);
	}

	if (dateTo) {
		dateTo = new Date(dateTo).toLocaleDateString("de-DE", options);
	}

	if (dateFrom && dateTo) {
		date = (
			<Typography
				className={clsx("teaser-item__date", css.date)}
				dangerouslySetInnerHTML={{ __html: dateFrom + " - " + dateTo }}
				variant="caption"
			></Typography>
		);
	} else if (dateFrom && !dateTo) {
		date = (
			<Typography
				className={clsx("teaser-item__date", css.date)}
				dangerouslySetInnerHTML={{ __html: dateFrom }}
				variant="caption"
			></Typography>
		);
	}

	return (
		<div className={clsx("teaser-item", css.root)}>
			<a
				className={clsx(css.a, {
					[css.aNoClick]: link === "http:///" || link === "https:///" || !link
				})}
				href={link}
				target={linkTarget}
				rel={linkRel}
			>
				<div className={clsx("teaser-item__image", css.imageWrapper)}>
					{!isEmpty(media) ? (
						<ReactHTMLPicture
							media={media}
							path={mediaPath}
							webpFallback={true}
							{...mediaFormats}
						/>
					) : (
						<div className={css.fallback}>
							<img
								src="/img/logo-innovationsraum.svg"
								alt="fallback"
								height="78"
								width="181"
								loading="lazy"
							></img>
						</div>
					)}
				</div>
				<Typography
					className={clsx("teaser-item__title", css.title)}
					dangerouslySetInnerHTML={{ __html: title }}
					variant="h6"
				></Typography>
				{date}
				{text && (
					<Typography
						className={clsx("teaser-item__text")}
						dangerouslySetInnerHTML={{ __html: text }}
						variant="body2"
					></Typography>
				)}
			</a>
		</div>
	);
};

TeaserItem.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string.isRequired,
	media: PropTypes.object,
	text: PropTypes.string,
	dateFrom: PropTypes.string,
	dateTo: PropTypes.string
};

export default TeaserItem;
