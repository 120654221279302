import makeStyles from "@material-ui/core/styles/makeStyles";
import Image from "../../../utils/image";

const useStyles = makeStyles((theme) => ({
	logo: {
		display: "flex",
		width: 150
	},
	logoFreiraum: {
		alignItems: "center",
		boxSizing: "content-box",
		display: "flex",
		height: 105,
		paddingTop: 16,
		width: 70
	},
	logoGrenzenlos: {
		alignItems: "center",
		background: "#FFFFFF",
		boxSizing: "content-box",
		display: "flex",
		height: 68,
		padding: theme.spacing(2, 3),
		width: 100
	},
	logoBio: {
		alignItems: "center",
		background: "#FFFFFF",
		boxSizing: "content-box",
		display: "flex",
		height: 67,
		padding: theme.spacing(1.5, 2),
		width: 100
	}
}));

const Logo = ({ layoutVariant }) => {
	const css = useStyles();

	if (layoutVariant === "inno" || layoutVariant === "egen") {
		<a className={css.logo} href="/">
			<Image
				height="122"
				width="150"
				filename="/img/logo-innovationsraum-footer.svg"
				alt="Coworkingspace Kufstein"
				disableCover
			/>
		</a>;
	}

	if (layoutVariant === "freiraum") {
		return (
			<a className={css.logoFreiraum} href="/">
				<Image
					filename="/img/logo-freiraum.png"
					alt="Freiraum@Interalp"
					height="164"
					width="109"
					disableCover
				/>
			</a>
		);
	}

	if (layoutVariant === "grenzenlos") {
		return (
			<a className={css.logoGrenzenlos} href="/">
				<Image
					filename="/img/logo-grenzenlos.png"
					alt="Grenzenlos Coworking Kössen"
					height="123"
					width="180"
					disableCover
				/>
			</a>
		);
	}

	if (layoutVariant === "bio") {
		return (
			<a className={css.logoBio} href="/">
				<Image
					filename="/img/logo-bio.png"
					alt="Bio Käserei"
					height="121"
					width="180"
					disableCover
				/>
			</a>
		);
	}

	if (layoutVariant === "egen") {
		return (
			<a className={css.logo} href="/">
				<Image
					height="122"
					width="150"
					filename="/img/logo-inno-footer.svg"
					alt="Coworkingspace Kufstein"
					disableCover
				/>
			</a>
		);
	}

	if (layoutVariant === "inno") {
		return (
			<a className={css.logo} href="/">
				<Image
					height="122"
					width="150"
					filename="/img/logo-inno-footer.svg"
					alt="Coworkingspace Kufstein"
					disableCover
				/>
			</a>
		);
	}

	return <></>;
};

export default Logo;
