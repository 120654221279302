import { useContext } from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PageContext from "@PageContext";
import Container from "@material-ui/core/Container";
import Logo from "./logo";
import ReactMenu01 from "@micado-digital/react-menu/ReactMenu01";
import { useScroll } from "./../../../hooks/useScroll";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.background.header,
		height: 64,
		left: 0,
		padding: theme.spacing(0),
		position: "fixed",
		top: 0,
		width: "100%",
		zIndex: 1100,
		"& .header-wrapper": {
			display: "flex",
			height: "100%",
			justifyContent: "space-between"
		},
		"& .header-wrapper--centered": {
			justifyContent: "flex-end",
			[theme.breakpoints.up("md")]: {
				justifyContent: "center"
			}
		},
		"& .logo a": {
			transition: theme.transitions.create(["height", "padding", "width"], {
				easing: theme.transitions?.easing?.easeInOut,
				duration: theme.transitions?.duration?.shorter
			})
		}
	},
	fixedHeader: {
		"& .logo a": {
			alignItems: "center",
			display: "flex",
			maxWidth: 100,
			[theme.breakpoints.up("sm")]: {
				maxWidth: 120
			}
		},
		"& .logo-freiraum a": {
			[theme.breakpoints.up("sm")]: {
				paddingTop: 0
			}
		},
		"& .logo-grenzenlos a": {
			alignItems: "center",
			display: "flex",
			height: 68,
			padding: theme.spacing(1, 2),
			width: 100,
			[theme.breakpoints.up("sm")]: {
				height: 96,
				padding: theme.spacing(2, 3),
				width: 160
			}
		}
	},
	headerFreiraum: {
		background: "#97BF0D"
	},
	headerGrenzenlos: {
		background: "#96C11F"
	},
	headerInno: {
		background: "#645a5a"
	}
}));

const Header = ({ hasAnchorMenu }) => {
	const [pageData] = useContext(PageContext);
	const css = useStyles();
	const isIntroNoLogo = pageData?.layoutVariant === "intro-nologo";

	if (isIntroNoLogo) {
		document.body.classList.add("intro-nologo");
	} else {
		document.body.classList.remove("intro-nologo");
	}

	const { scrollY } = useScroll();

	const mainmenu = pageData?.menuGroups?.find((item) => item.title === "Hauptmenü");
	const metamenu = pageData?.menuGroups?.find((item) => item.title === "Metamenü");

	const header = document.querySelector("header");

	return (
		<header
			className={clsx(
				"header",
				css.root,
				{ [css.fixedHeader]: scrollY > 100 },
				{ [css.headerFreiraum]: pageData?.layoutVariant === "freiraum" },
				{ [css.headerGrenzenlos]: pageData?.layoutVariant === "grenzenlos" },
				{
					[css.headerInno]:
						pageData?.layoutVariant === "egen" || pageData?.layoutVariant === "inno"
				}
			)}
		>
			<Container
				className={clsx("header-wrapper", { "header-wrapper--centered": isIntroNoLogo })}
				maxWidth="lg"
			>
				{!isIntroNoLogo && (
					<Logo
						currentURL={pageData?.pageName}
						hasAnchorMenu={hasAnchorMenu}
						layoutVariant={pageData?.layoutVariant}
					/>
				)}
				<ReactMenu01
					backLabel="Zurück"
					desktopSubContainerEvent="mouseenter"
					desktopSubContainerLayoutColumn="vertical"
					iconBottom="/img/icons/chevron-down.svg"
					iconClose="/img/icons/close.svg"
					iconLeft="/img/icons/chevron-left.svg"
					iconMenu="/img/icons/menu.svg"
					iconRight="/img/icons/chevron-right.svg"
					mainmenu={mainmenu}
					metamenu={metamenu}
					toggleAlign="ltr"
					toggleLabel="Menü"
					onClose={() => {
						header && header.classList.remove("menu-visible");
					}}
					onOpen={() => {
						header && header.classList.add("menu-visible");
					}}
				/>
			</Container>
		</header>
	);
};

export default Header;
