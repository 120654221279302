import React, { useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import Contact from "./contact";
import Container from "@material-ui/core/Container";
import Copyright from "./copyright";
import Footermenu from "./footermenu";
import Logo from "./logo";
import Metamenu from "./metamenu";
import PageContext from "@PageContext";
import SocialLinks from "./socialLinks";
import WebProfileContext from "@WebProfileContext";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.background.footer,
		padding: theme.spacing(5, 3),
		[theme.breakpoints.up("lg")]: {
			padding: theme.spacing(7, 3, 5)
		}
	},
	footerFreiraum: {
		background: "#E7E9ED",
		"& *": {
			color: "#2E2B2B !important"
		}
		// "& .top-wrapper": {
		// 	[theme.breakpoints.up("lg")]: {
		// 		marginTop: 0
		// 	}
		// }
	},
	footerGrenzenlos: {
		background: "#3C3C3B"
		// "& .top-wrapper": {
		// 	[theme.breakpoints.up("lg")]: {
		// 		marginTop: 0
		// 	}
		// }
	},
	footerInno: {
		background: "#645a5a"
	},
	containerFooter: {
		padding: theme.spacing(0)
	},
	logoWrapper: {
		display: "flex",
		justifyContent: "center"
	},
	addressWrapper: {
		color: theme.palette.common.white,
		lineHeight: "32px",
		margin: theme.spacing(8, 0),
		[theme.breakpoints.up("md")]: {
			display: "flex",
			justifyContent: "space-between"
		},
		[theme.breakpoints.up("lg")]: {
			flex: "1",
			margin: theme.spacing(2, 17, 8, 0)
		}
	},
	topWrapper: {
		[theme.breakpoints.up("lg")]: {
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-between",
			marginTop: theme.spacing(6)
		}
	},
	bottomWrapper: {
		alignItems: "center",
		display: "flex",
		flexWrap: "wrap",
		[theme.breakpoints.up("lg")]: {
			justifyContent: "space-between"
		}
	},
	copyright: {
		flex: "1 0 175px",
		marginRight: "auto",
		[theme.breakpoints.up("lg")]: {
			flex: "unset",
			marginRight: theme.spacing(4),
			order: 1
		}
	},
	footermenu: {
		marginBottom: theme.spacing(8),
		[theme.breakpoints.up("lg")]: {
			flex: "0 0 287px"
		}
	},
	metamenu: {
		flex: "1 0 100%",
		marginTop: theme.spacing(3),
		[theme.breakpoints.up("lg")]: {
			flex: "unset",
			marginTop: theme.spacing(0),
			order: 2
		}
	},
	socialLinks: {
		[theme.breakpoints.up("lg")]: {
			order: 3
		}
	}
}));

const Footer = ({ addresses }) => {
	const [pageData] = useContext(PageContext);
	const [profileData] = useContext(WebProfileContext);
	const variant = pageData?.layoutVariant;
	const css = useStyles();

	const footermenu = pageData?.menuGroups.find((item) => item.title === "Footermenü");
	const footermenuFreiraum = pageData?.menuGroups.find(
		(item) => item.title === "Footermenü (Freiraum)"
	);
	const footermenuGrenzenlos = pageData?.menuGroups.find(
		(item) => item.title === "Footermenü (Grenzenlos)"
	);
	const footermenuBio = pageData?.menuGroups.find(
		(item) => item.title === "Footermenü (Bio Käserei)"
	);
	const metamenu = pageData?.menuGroups.find((item) => item.title === "Metamenü");
	const metamenuFreiraum = pageData?.menuGroups.find(
		(item) => item.title === "Metamenü (Freiraum)"
	);
	const metamenuGrenzenlos = pageData?.menuGroups.find(
		(item) => item.title === "Metamenü (Grenzenlos)"
	);
	const metamenuBio = pageData?.menuGroups.find(
		(item) => item.title === "Metamenü (Bio Käserei)"
	);
	const socialLinks = profileData?.links;
	// const showLogo = variant !== "freiraum" && variant !== "grenzenlos";

	const getFooterMenu = () => {
		switch (variant) {
			case "freiraum":
				return footermenuFreiraum;
			case "grenzenlos":
				return footermenuGrenzenlos;
			case "bio":
				return footermenuBio;

			default:
				return footermenu;
		}
	};

	const getMetaMenu = () => {
		switch (variant) {
			case "freiraum":
				return metamenuFreiraum;
			case "grenzenlos":
				return metamenuGrenzenlos;
			case "bio":
				return metamenuBio;

			default:
				return metamenu;
		}
	};

	const getSocialLinks = () => {
		switch (variant) {
			case "freiraum":
				return [
					{
						identifier: "",
						title: "Twitter",
						type: "twitter",
						url: "https://twitter.com/FreiraumIntera1"
					},
					{
						identifier: "",
						title: "Instagram",
						type: "instagram",
						url: "https://www.instagram.com/freiraum_coworking/"
					},
					{
						identifier: "",
						title: "YouTube",
						type: "youtube",
						url: "https://www.youtube.com/channel/UCEMgwkiCU8z6yvu02HBCSxA"
					}
				];
			case "grenzenlos":
				return [
					{
						identifier: "",
						title: "Instagram",
						type: "instagram",
						url: "https://www.instagram.com/grenzenlos_coworking/"
					},
					{
						identifier: "",
						title: "Facebook",
						type: "facebook",
						url: "https://www.facebook.com/grenzenloscoworking"
					}
				];

			default:
				return socialLinks;
		}
	};

	return (
		<footer
			className={clsx(css.root, {
				[css.footerFreiraum]: variant === "freiraum",
				[css.footerGrenzenlos]: variant === "grenzenlos",
				[css.footerInno]: variant === "inno" || variant === "egen"
			})}
		>
			<Container className={clsx("container-footer", css.containerFooter)} maxWidth="lg">
				<div className={clsx("logo-wrapper", css.logoWrapper)}>
					<Logo layoutVariant={variant} />
				</div>
				<div className={clsx("top-wrapper", css.topWrapper)}>
					<div className={clsx("address-wrapper", css.addressWrapper)}>
						<Contact data={addresses} variant={variant} />
					</div>
					{footermenu && (
						<div className={css.footermenu}>
							<Footermenu data={getFooterMenu()} variant={variant} />
						</div>
					)}
				</div>
				<div className={clsx("bottom-wrapper", css.bottomWrapper)}>
					<div className={css.copyright}>
						<Copyright variant={variant} />
					</div>
					{getSocialLinks() && getSocialLinks()?.length > 0 && (
						<div className={css.socialLinks}>
							<SocialLinks data={getSocialLinks()} />
						</div>
					)}
					{metamenu && (
						<div className={css.metamenu}>
							<Metamenu data={getMetaMenu()} />
						</div>
					)}
				</div>
			</Container>
		</footer>
	);
};

export default Footer;
