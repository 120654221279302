import React from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	quoteWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"
	},
	image: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginBottom: theme.spacing(3),
		"& img": {
			borderRadius: "50%",
			margin: "0 auto",
			width: "160px !important"
		}
	},
	text: {
		fontStyle: "italic",
		marginBottom: theme.spacing(1),
		textAlign: "center"
	},
	author: {
		textAlign: "center"
	}
}));

const Quote = ({ text, author, media, mediaFormats, mediaPath }) => {
	const css = useStyles();

	return (
		<div className={clsx("quote", css.quoteWrapper)}>
			{!isEmpty(media) && (
				<div className={clsx("quote__image", css.image)}>
					<ReactHTMLPicture
						media={media}
						path={mediaPath}
						webpFallback={true}
						{...mediaFormats}
					/>
				</div>
			)}
			{text && (
				<Typography
					className={clsx("quote__text", css.text)}
					component="p"
					dangerouslySetInnerHTML={{ __html: text }}
					variant="body1"
				/>
			)}
			{text && (
				<Typography
					className={clsx("quote__author", css.author)}
					component="p"
					dangerouslySetInnerHTML={{ __html: author }}
					variant="h6"
				/>
			)}
		</div>
	);
};

Quote.propTypes = {
	media: PropTypes.object.isRequired,
	mediaFormats: PropTypes.object,
	mediaPath: PropTypes.string,
	text: PropTypes.string,
	title: PropTypes.string
};

export default Quote;
