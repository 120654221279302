import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "block",
		"& img": {
			display: "block",
			width: "100%"
		}
	}
}));

const Organigramm = ({ media, mediaFormats, mediaPath }) => {
	const css = useStyles();

	return (
		<div className={clsx("organigramm", css.root)}>
			<ReactHTMLPicture media={media} path={mediaPath} webpFallback={true} {...mediaFormats} />
		</div>
	);
};

Organigramm.propTypes = {
	media: PropTypes.object.isRequired,
	mediaFormats: PropTypes.object,
	mediaPath: PropTypes.string
};

export default Organigramm;
